import React, { useContext, useState, useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from '@bootstrap-styled/v4'
import {
  Layout,
  SEO,
  Section,
  Image,
  HeroHeading,
  Paragraph,
  Box,
  Heading,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalClose,
  References,
  Icon,
  ScrollDownArrow,
} from '@components'
import { Page, ACF, Service, TextAlignment } from '@types'
import { ThemeContext } from 'styled-components'
import { getFileName, formatPhoneNumber } from '@utils'
import {
  useServices,
  useLabels,
  useReferences,
  useBenefits,
  useSiteMetadata,
  useContact,
} from '@hooks'

type HomepageACF = ACF<{
  herotitle: string
  heroimage: {
    sourceUrl: string
  }
  herodescription: string
}>

type ServiceModal = Service & {
  isOpen: boolean
}

const IndexPage: React.FC<Page<HomepageACF>> = ({ data, location }) => {
  const [serviceModal, setServiceModal] = useState<ServiceModal>({
    isOpen: false,
    id: 0,
    shortDescription: '',
    title: '',
    content: '',
    url: '',
  })
  const { transitionDurations, colors } = useContext(ThemeContext)
  const services = useServices()
  const references = useReferences()
  const benefits = useBenefits()
  const labels = useLabels()
  const site = useSiteMetadata()
  const contact = useContact()
  const { title, acf } = data.wpgraphql.page

  const heroImage = getFileName(acf?.heroimage.sourceUrl)

  const closeModal = useCallback(() => {
    setServiceModal({
      ...serviceModal,
      isOpen: false,
    })
  }, [serviceModal])

  const modalFullContent = useMemo(() => {
    return `${serviceModal.content ?? ''}`
  }, [serviceModal])

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />

      {/* Intro */}
      <Section fullPage hasBg isFirst vCenter>
        <Container>
          <Row className="align-items-center">
            <Col xs="12" lg="6">
              <HeroHeading
                align={TextAlignment.LEFT}
                data-sal="slide-up"
                data-sal-duration={transitionDurations.sal}
              >
                {acf?.herotitle}
              </HeroHeading>
              {/* <div data-sal="slide-up" data-sal-duration={transitionDurations.sal}>
                <Alert color="success">
                  <Heading variant="h2" textSize="xxlarge" fontWeight="bold">
                    Dovolená od 10.9. do 17.9.
                  </Heading>
                  <Paragraph className="mb-0">
                    V případě zájmu o termín na dentální hygienu nás kontaktujte přes{' '}
                    <Link to="/kontakt/">objednávkový formulář</Link> a my se Vám po dovolené
                    ozveme.
                  </Paragraph>
                </Alert>
              </div> */}
            </Col>
            <Col xs="12" lg="6">
              {heroImage && <Image name={heroImage} />}
              <Paragraph textSize="large" className="mb-0">
                {site.owner}
              </Paragraph>
              {acf?.herodescription && (
                <Paragraph textSize="small" className="mb-4 mt-1">
                  {acf?.herodescription}
                </Paragraph>
              )}
            </Col>
          </Row>
        </Container>
        <ScrollDownArrow href="#services" label={labels.moreInfo} />
      </Section>

      <Section id="services" delimiter aria-hidden="true" />

      {/* Services */}
      <Section hasBg>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" lg="10" xl="9">
              <Box overflow="vertical">
                {services.length > 0 && (
                  <Row className="justify-content-center">
                    {services.map(service => (
                      <Col
                        key={service.id}
                        xs="12"
                        lg="6"
                        className="mb-4"
                        data-sal="slide-up"
                        data-sal-duration={transitionDurations.sal}
                      >
                        <Heading variant="h2" textSize="xxxlarge" color={colors.white}>
                          {service.title}
                        </Heading>
                        <div dangerouslySetInnerHTML={{ __html: service.shortDescription }} />
                        <Button
                          color="link"
                          linkui={1}
                          vCenter={1}
                          onClick={() =>
                            setServiceModal({
                              ...service,
                              isOpen: true,
                            })
                          }
                        >
                          <span>{labels.moreInfo}</span>
                          <Icon name="ArrowRight" width={7} height={14} />
                        </Button>
                      </Col>
                    ))}
                  </Row>
                )}
                <div className="text-center">
                  <Button color="secondary" to="/kontakt/" size="lg">
                    {labels.booking}
                  </Button>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section delimiter aria-hidden="true" />

      {/* Why me */}
      <Section hasBg verticalPadding vCenter>
        <Container>
          <div className="text-center mb-5">
            <HeroHeading
              as="h2"
              className="mb-0 d-inline-block"
              align={TextAlignment.CENTER}
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {labels.whyMe}
            </HeroHeading>
          </div>
          <Row className="justify-content-center">
            {benefits.map(benefit => (
              <Col
                key={benefit.id}
                xs="12"
                sm="6"
                lg="4"
                className="mb-5 text-center"
                data-sal="slide-up"
                data-sal-duration={transitionDurations.sal}
              >
                <div className="mb-3">
                  <Icon name={benefit.icon} size={48} />
                </div>
                <Heading variant="h3" textSize="xxxlarge" align={TextAlignment.CENTER}>
                  {benefit.title}
                </Heading>
                <Paragraph textSize="base" className="my-0">
                  {benefit.description}
                </Paragraph>
              </Col>
            ))}
          </Row>
          <div className="text-center">
            <Button color="primary" to="/kontakt/" size="lg">
              {labels.booking}
            </Button>
          </div>
        </Container>
      </Section>

      <Section delimiter aria-hidden="true" />

      {/* References */}
      {references.length > 0 && (
        <Section hasBg verticalPadding vCenter>
          <Container>
            <div className="text-center mb-lg-5">
              <HeroHeading
                as="h2"
                className="mb-0 d-inline-block"
                align={TextAlignment.CENTER}
                data-sal="slide-up"
                data-sal-duration={transitionDurations.sal}
              >
                {labels.reference}
              </HeroHeading>
            </div>
            <Row>
              <Col xs="12" lg="11">
                <Row className="align-items-center justify-content-center">
                  <Col
                    xs="12"
                    lg="6"
                    className="text-center my-4"
                    data-sal="slide-up"
                    data-sal-duration={transitionDurations.sal}
                  >
                    <Icon name="Logo" width={200} height={190} />
                  </Col>
                  <Col xs="12" sm="10" lg="6">
                    <References dataSource={references} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      <Section delimiter aria-hidden="true" />

      {/* Contact section */}
      <Section hasBg verticalPadding>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" lg="7">
              <Row className="justify-content-between">
                <Col
                  xs="12"
                  sm="6"
                  lg="5"
                  data-sal="slide-up"
                  data-sal-duration={transitionDurations.sal}
                >
                  <div className="mb-lg-4">
                    <Heading variant="h2" textSize="xxxlarge" className="mb-0">
                      {labels.contact}
                    </Heading>
                  </div>
                  <Paragraph>
                    <Paragraph as="strong" textSize="xlarge">
                      {site.title}
                    </Paragraph>
                    <br />
                    {site.owner}
                  </Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: contact.address }} />
                  <Paragraph className="mb-5 mb-lg-3">
                    {labels.email} <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    <br />
                    {labels.phoneNumber}{' '}
                    <a href={`tel:${contact.phoneNumber}`}>
                      {formatPhoneNumber(contact.phoneNumber)}
                    </a>
                  </Paragraph>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  lg="5"
                  data-sal="slide-up"
                  data-sal-duration={transitionDurations.sal}
                >
                  <div className="mb-3 mb-lg-4">
                    <Heading variant="h2" textSize="xxxlarge" className="mb-0">
                      {labels.officeHours}
                    </Heading>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: contact.officeHours }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Service modal */}
      <Modal
        isOpen={serviceModal.isOpen}
        labelledby="serviceModalTitle"
        onRequestClose={closeModal}
      >
        <ModalHeader id="serviceModalTitle" title={serviceModal.title}>
          <ModalClose onClick={closeModal} />
        </ModalHeader>
        <ModalContent>
          <div dangerouslySetInnerHTML={{ __html: modalFullContent }} />
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "11", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        isFrontPage
        content(format: RENDERED)
        acf {
          herotitle
          heroimage {
            sourceUrl
          }
          herodescription
        }
      }
    }
  }
`
